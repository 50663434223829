<template>
  <div>
     <Row :gutter="5" class="m-b-10">
      <i-col  :xs="24" :sm="12" :md="6" class="m-b-5">
        <DatePicker size="small" class="text-black" placeholder="开始时间" v-model="agreementChangeQuery.startDate" @on-change="agreementChangeQuery.startDate=$event" style="width:100%"></DatePicker>
      </i-col>
      <i-col  :xs="24" :sm="12" :md="6" class="m-b-5">
        <DatePicker size="small" class="text-black" placeholder="结束时间" v-model="agreementChangeQuery.endDate" @on-change="agreementChangeQuery.endDate=$event" style="width:100%"></DatePicker>
      </i-col>
        <i-col  v-if="isAuth('Agreement_Manage_View')" :xs="24" :sm="12" :md="6" class="m-b-5">
                <Select size="small" v-model="agreementChangeQuery.companyId" placeholder="所属公司" clearable @on-change="companyChange">
                <Option v-for="(channel,index) in channellist" :key="index" :value="channel.id">{{channel.name}}</Option>
                </Select>
            </i-col>
             <i-col  v-if="isAuth('Agreement_Manage_View')" :xs="24" :sm="12" :md="6" class="m-b-5">
                <Select size="small" v-model="agreementChangeQuery.departmentId" placeholder="所属部门" clearable @on-change="departmentChange">
                  <Option v-for="(department,index) in departments" :key="index" :value="department.id">{{department.name}}</Option>
                </Select>
            </i-col>
            <i-col v-if="isAuth('Agreement_Manage_View')"  :xs="24" :sm="12" :md="6" class="m-b-5">
                <Select size="small" v-model="agreementChangeQuery.userId" placeholder="归属销售" clearable>
                <Option v-for="user in channelUsers" :key="user.id" :value="user.id">{{ user.name}}</Option>
                </Select>
            </i-col>
      <i-col  :xs="24" :sm="12" :md="6" class="m-b-5">
        <i-select  size="small"  placeholder="状态" v-model="agreementChangeQuery.status" multiple>
             <i-option v-for="item in statusArray" :key='item.id' :value="item.id">{{item.name}}</i-option>
        </i-select>
      </i-col>
      <i-col  :xs="24" :sm="12" :md="6" class="m-b-5">
        <i-input size="small"  placeholder="关键字：支持名称查询" v-model="agreementChangeQuery.keyword">
        </i-input>
      </i-col>
      <i-col :xs="24" :sm="12" :md="6" class="m-b-5">
        <i-button  size="small" icon="ios-search" type="primary" @click="handleSearch">搜索</i-button>
      </i-col>
    </Row>
    <!-- 变更列表 -->
    <i-table :data="list" :columns="columns" stripe></i-table>
    <div class="paging_style">
      <Page size="small" :total="total" :current="agreementChangeQuery.pageNumber"  :page-size="agreementChangeQuery.pageSize" @on-change="handlePageChange" show-total show-elevator></Page>
    </div>

  </div>
</template>

<script>
import { getContractChangePage, deleteVersion } from '@/api/scp/contractchange'
import { formatContractStatus } from '@/utils/tagStatus'
import { getCompanyChild } from '@/api/os/company'
import { getSellerPage } from '@/api/os/companyuser'

export default {
  data () {
    return {

      channellist: [],
      departments: [],
      channelUsers: [],
      total: 0,
      list: [],
      columns: [
        { title: '编码', key: 'code' },
        { title: '确认单名称', key: 'name' },
        { title: '客户名称', key: 'advertiserName' },
        { title: '创建时间', key: 'createTime' },
        { title: '审批通过时间', key: 'approvedTime' },
        {
          title: '状态',
          width: 120,
          render: (h, data) => {
            return formatContractStatus(h, data.row.status, data.row.statusName)
          }
        },
        {
          title: '操作',
          render: (h, data) => {
            const editableStatus = [0, 6]
            const updateButton = h('a', {
              style: {
                marginRight: '5px'
              },
              on: {
                click: () => {
                  this.handleEdit(data.row)
                }
              }
            }, '编辑')
            const deleteButton = h('a', {
              style: {
                marginRight: '5px',
                color: '#ef4f4f'
              },
              on: {
                click: () => {
                  this.handleDelete(data.row)
                }
              }
            }, '删除')
            const detailButton = h('a', {
              style: {
                marginRight: '5px'
              },
              on: {
                click: () => {
                  this.handleDetail(data.row)
                }

              }
            }, '详情')

            if (this.isAuth('Agreement_Edit') && editableStatus.includes(data.row.status)) {
              return h('div', [detailButton, updateButton, deleteButton])
            }
            return h('div', [detailButton])
          }

        }
      ],
      statusArray: []
    }
  },
  methods: {
    initPage () {
      this.channellist = []
      getCompanyChild({ companyId: this.$store.getters.token.userInfo.companyId, type: this.$store.getters.token.userInfo.publisherId === this.$store.getters.token.userInfo.companyId ? 1 : 2, childType: 2, companyType: 1 }).then(res => {
        let array = [{
          id: res.companyId, name: res.companyName
        }]
        if (res.children.length) {
          array = array.concat(this.formatCompanyArray(res.children).filter(x => x.id !== res.companyId))
        }
        this.channellist = array
        if (!this.agreementChangeQuery.companyId) {
          if (array.length > 1 && this.isAuth('Agreement_Manage_View')) {
            this.agreementChangeQuery.companyId = null
          } else {
            this.agreementChangeQuery.companyId = this.$store.getters.token.userInfo.companyId
          }
        }
        this.initdepartments()
      })
    },
    companyChange () {
      this.agreementChangeQuery.departmentId = ''
      this.agreementChangeQuery.userId = ''
      this.initdepartments()
    },
    departmentChange () {
      this.agreementChangeQuery.userId = ''
      this.getChannelUserList()
    },
    initdepartments () {
      this.departments = []
      if (this.agreementChangeQuery.companyId) {
        getCompanyChild({ companyId: this.agreementChangeQuery.companyId, type: 2, childType: 2, companyType: 2 }).then(res => {
          let array = [{
            id: res.companyId, name: res.companyName
          }]
          if (res.children.length) {
            array = array.concat(this.formatCompanyArray(res.children).filter(x => x.id !== res.companyId))
          }
          this.departments = array
          // this.agreementChangeQuery.departmentId = null
          this.getChannelUserList()
        })
      } else {
        this.getChannelUserList()
      }
    },

    // 重组公司列表结构
    formatCompanyArray (companyTree) {
      let resultArray = []
      companyTree.forEach(item => {
        resultArray.push({ id: item.companyId, name: item.companyName })
        if (item.children.length) {
          resultArray = resultArray.concat(this.formatCompanyArray(item.children))
        }
      })
      return resultArray
    },
    getChannelUserList () {
      this.channelUsers = []
      const data = {
        companyId: this.agreementChangeQuery.departmentId || this.agreementChangeQuery.companyId,
        pageNumber: 1,
        pageSize: 1000
      }
      if (data.companyId) {
        getSellerPage(data).then(response => {
          this.channelUsers = response.list
        })
      } else {
        this.agreementChangeQuery.userId = ''
      }
      this.getList()
    },

    getList () {
      if (this.agreementChangeQuery.status) {
        this.agreementChangeQuery.statusList = JSON.stringify(this.agreementChangeQuery.status)
      } else {
        this.agreementChangeQuery.statusList = JSON.stringify(this.statusArray.map(x => { return x.id }))
      }
      // this.agreementChangeQuery.statusList = JSON.stringify([this.status])

      this.$store.commit('set_query_agreementChangeQuery', this.agreementChangeQuery)
      getContractChangePage(this.agreementChangeQuery).then(res => {
        this.list = res.list
        this.total = res.totalRow
      })
    },
    handlePageChange (page) {
      this.agreementChangeQuery.pageNumber = page
      this.getList()
    },
    handleEdit (contractChange) {
      this.$emit('on-edit', contractChange.contractId, contractChange.versionId)
    },
    handleDetail  (contractChange) {
      this.$emit('on-detail', contractChange.contractId, contractChange.versionId)
    },
    handleDelete (contractChange) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '是否确定要删除该变更单',
        onOk: () => {
          deleteVersion({ versionId: contractChange.versionId }).then(res => {
            if (res && !res.errcode) {
              this.agreementChangeQuery.pageNumber = 1
              this.getList()
              // 更新左侧菜单
              this.$store.commit('set_contract_update', new Date())
              this.$Notice.success({ desc: '删除成功' })
            }
          })
        }
      })
    },
    handleSearch () {
      this.agreementChangeQuery.pageNumber = 1
      this.getList()
    },
    // 加载可用的状态
    initStatusArray () {
      const that = this
      that.statusArray = [
        { id: 0, name: '草稿' },
        { id: -1, name: '已废弃' }
      ]
      const manageStatus = [
        { id: 4, name: '待审批' },
        { id: 5, name: '已通过' },
        { id: 6, name: '已拒绝' }
        // { id: 9, name: '已归档' }
      ]
      if (that.isAuth('Agreement_Sale_View')) {
        that.statusArray = that.statusArray.concat(manageStatus)
      } else {
        that.statusArray = manageStatus
      }
    }
  },
  created () {
    this.agreementChangeQuery.userId = this.isAuth('Agreement_Manage_View') ? this.agreementChangeQuery.userId : this.$store.getters.token.userInfo.userId
    this.initStatusArray()
    this.initPage()
  },
  computed: {
    agreementChangeQuery () {
      return this.$store.state.contractQuery.agreementChangeQuery
    }
  }

}
</script>
