import request from '@/utils/requestV2'
const qs = require('qs')

// 变更合同
export function changeContract (data) {
  return request({
    url: '/ooh-scp/v1/contractchange/changecontract',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 条件查询所有合同的合同变更列表，分页显示
export function getContractChangePage (data) {
  return request({
    url: '/ooh-scp/v1/contractchange/getcontractchangepage',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 查看某次合同变更详情
export function getVersionDetail (data) {
  return request({
    url: '/ooh-scp/v1/contractchange/getversiondetail',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取特定合同所有变更记录
export function getVersionList (data) {
  return request({
    url: '/ooh-scp/v1/contractchange/getversionlist',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 删除变更（仅草稿状态可删除）
export function deleteVersion (data) {
  return request({
    url: '/ooh-scp/v1/contractchange/deleteversion',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 预测合同绩效
export function predictCommission (data) {
  return request({
    url: '/ooh-scp/v1/contractchange/predictcommission',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取媒介主合同可用的销售绩效类型
export function getPublisherContractCommissionTypeList (data) {
  return request({
    url: '/ooh-scp/v1/contractchange/getpublishercontractcommissiontypelist',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取到合同变更版本标识（若没有，会创建一个返回。若存在当前进行中的变更，则返回当前进行中的变更标识id。）
export function getContractChangingVersionId (data) {
  return request({
    url: '/ooh-scp/v1/contractchange/getcontractchangingversionid',
    method: 'post',
    data: qs.stringify(data)
  })
}
